import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { Card } from 'antd';

// Configuração do Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCboGZey8N5fTPjhzExQ2EdwnMxMcZkyKY",
    authDomain: "login-3159d.firebaseapp.com",
    projectId: "login-3159d",
    storageBucket: "login-3159d.firebasestorage.app",
    messagingSenderId: "503710927958",
    appId: "1:503710927958:web:df5506d167ce857722fff7"
};

// Inicializa o Firebase e o Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const SimpleDashboard = () => {
    const [meta, setMeta] = useState(0); // Meta para faturamento
    const [metaInvestimento, setMetaInvestimento] = useState(0); // Meta para investimento
    const [projectedSpend, setProjectedSpend] = useState(0); // Projeção de valor gasto
    const [projectedRevenue, setProjectedRevenue] = useState(0); // Projeção de valor faturado

    useEffect(() => {
        const fetchMetaAndApiData = async () => {
            try {
                const adAccountCode = String(localStorage.getItem('token')).trim();
                if (!adAccountCode) {
                    console.error("Token não encontrado no localStorage");
                    return;
                }

                // Consulta o Firestore para buscar a meta e a meta de investimento
                const usersCollection = collection(db, "users");
                const q = query(usersCollection, where("adAccountCode", "==", adAccountCode));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docData = querySnapshot.docs[0].data();
                    setMeta(parseFloat(docData.meta || 0)); // Meta para faturamento
                    setMetaInvestimento(parseFloat(docData.metaInvestimento || 0)); // Meta para investimento

                    // Busca dados da API
                    const response = await fetch(`https://ads-meta-867395045570.us-central1.run.app/api/insights/${adAccountCode}`);
                    const apiData = await response.json();

                    // Cálculo da projeção
                    if (apiData.length > 0) {
                        const totalSpend = apiData.reduce((acc, item) => acc + parseFloat(item.spend || 0), 0);
                        const totalRevenue = apiData.reduce((acc, item) => acc + parseFloat(item.purchase_value || 0), 0);

                        const recordedDays = apiData.length; // Dias registrados na API
                        const currentDate = new Date();
                        const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

                        // Projeção até o final do mês
                        const projectedSpendValue = (totalSpend / recordedDays) * daysInMonth;
                        const projectedRevenueValue = (totalRevenue / recordedDays) * daysInMonth;

                        setProjectedSpend(projectedSpendValue);
                        setProjectedRevenue(projectedRevenueValue);
                    }
                } else {
                    console.log("Nenhum documento com o adAccountCode fornecido foi encontrado!");
                }
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };

        fetchMetaAndApiData();
    }, []);

    const formatCurrency = (value) => {
        return `R$ ${parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    };

    const gradientBorderStyle = {
        background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
        padding: '1px',
        borderRadius: '10px',
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
            {/* Gráfico 1: Projeção do Valor Gasto x Meta de Investimento */}
            <div style={gradientBorderStyle}>
                <Card
                    title="Projeção do Valor Gasto x Meta de Investimento"
                    headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
                    bodyStyle={{ backgroundColor: '#1f1d39', padding: '0px' }}
                    bordered={false}
                    style={{ borderRadius: '8px', background: 'none', margin: '0' }}
                >
                    <ResponsiveContainer width={400} height={300}>
                        <BarChart data={[{ name: 'Projeção', Meta: metaInvestimento, Gasto: projectedSpend }]}>
                            <XAxis dataKey="name" />
                            <YAxis padding={{ top: 20 }} />
                            <Tooltip formatter={(value) => formatCurrency(value)} />
                            <Bar dataKey="Gasto" fill="#f8b100" name="Projeção do Gasto">
                                <LabelList dataKey="Gasto" position="insideTop" formatter={(value) => formatCurrency(value)} />
                            </Bar>
                            <Bar dataKey="Meta" fill="#4a148c" name="Meta de Investimento">
                                <LabelList dataKey="Meta" position="insideTop" formatter={(value) => formatCurrency(value)} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
            </div>

            {/* Gráfico 2: Projeção do Valor Faturado x Meta */}
            <div style={gradientBorderStyle}>
                <Card
                    title="Projeção do Valor Faturado x Meta"
                    headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
                    bodyStyle={{ backgroundColor: '#1f1d39', padding: '0px' }}
                    bordered={false}
                    style={{ borderRadius: '8px', background: 'none', margin: '0' }}
                >
                    <ResponsiveContainer width={400} height={300}>
                        <BarChart data={[{ name: 'Projeção', Meta: meta, Faturado: projectedRevenue }]}>
                            <XAxis dataKey="name" />
                            <YAxis padding={{ top: 20 }} />
                            <Tooltip formatter={(value) => formatCurrency(value)} />
                            <Bar dataKey="Faturado" fill="#f8b100" name="Projeção do Faturamento">
                                <LabelList dataKey="Faturado" position="insideTop" formatter={(value) => formatCurrency(value)} />
                            </Bar>
                            <Bar dataKey="Meta" fill="#4a148c" name="Meta">
                                <LabelList dataKey="Meta" position="insideTop" formatter={(value) => formatCurrency(value)} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
            </div>
        </div>
    );
};

export default SimpleDashboard;
