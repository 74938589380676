// Importa as funções necessárias do SDK do Firebase
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// 1ª Configuração do Firebase (padrão)
const firebaseConfig = {
  apiKey: "AIzaSyCboGZey8N5fTPjhzExQ2EdwnMxMcZkyKY",
  authDomain: "login-dashboard-3b427.firebaseapp.com",
  projectId: "login-dashboard-3b427",
  storageBucket: "login-dashboard-3b427.appspot.com",
  messagingSenderId: "1099463922095",
  appId: "1:1099463922095:web:83d57a3ea38cea8e4a5f5a"
};

// Verifica se o app padrão já existe antes de inicializar
const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);
export const auth = getAuth(app);

// 2ª Configuração do Firebase (com nome específico)
const firebaseConfigSolicitacoesApp = {
  apiKey: "AIzaSyDKKdQzZ8Udy3tHh0iGLyRq0wvmE1TMhII",
  authDomain: "painel-de-controle-654ce.firebaseapp.com",
  projectId: "painel-de-controle-654ce",
  storageBucket: "painel-de-controle-654ce.appspot.com",
  messagingSenderId: "815988540726",
  appId: "1:815988540726:web:940b5042e2abf4e7091f97"
};

// Verifica se a segunda instância específica já existe
const solicitacoesApp = getApps().some((app) => app.name === "solicitacoesApp")
  ? getApp("solicitacoesApp")
  : initializeApp(firebaseConfigSolicitacoesApp, "solicitacoesApp");

export const db = getFirestore(solicitacoesApp);
