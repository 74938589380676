import React, { useEffect, useState } from "react";
import { Timeline, Tooltip, Modal, Button, message, Card } from "antd";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase"; // Certifique-se de importar o db corretamente

const TimelineAtas = () => {
  const [atas, setAtas] = useState([]);
  const [selectedAta, setSelectedAta] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchAtasByCodigoBm = async () => {
    try {
      const tokenBM = localStorage.getItem("tokenBM"); // Pega o valor do localStorage

      if (!tokenBM) {
        message.warning("Código BM não encontrado no localStorage.");
        return;
      }

      // Converte tokenBM para número, pois no Firestore é um número
      const codigoBm = Number(tokenBM);

      if (isNaN(codigoBm)) {
        message.error("O código BM no localStorage não é válido.");
        return;
      }

      // Consulta no Firestore usando a conversão correta
      const q = query(collection(db, "atas"), where("codigoBm", "==", codigoBm));
      const querySnapshot = await getDocs(q);

      // Mapeia os dados encontrados
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Ordena os dados por data (mais recente primeiro)
      const sortedData = data.sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime());

      console.log("Documentos encontrados ordenados:", sortedData); // Log para depuração
      setAtas(sortedData);
    } catch (error) {
      console.error("Erro ao buscar ATAS:", error);
      message.error("Erro ao carregar as ATAS.");
    }
  };

  // Abrir modal com detalhes da ata
  const openModal = (ata) => {
    setSelectedAta(ata);
    setIsModalOpen(true);
  };

  // Fechar modal
  const closeModal = () => {
    setSelectedAta(null);
    setIsModalOpen(false);
  };

  // Buscar as ATAS assim que o componente for carregado
  useEffect(() => {
    fetchAtasByCodigoBm();
  }, []);

  return (
    <div style={{ backgroundColor: "#1a1d32", width: "98vw", borderRadius: "20px", padding: "10px", minHeight: "80vh" }}>
      <div style={{ maxWidth: "1000px", margin: "0 auto", textAlign: "center" }}>
        <h2>Atas Das Reuniões</h2>

        <Timeline mode="alternate">
          {atas.map((ata) => (
            <Timeline.Item key={ata.id} onClick={() => openModal(ata)}>
              <Card style={{ backgroundColor: "#292c49", border: "none", color: "white" }}>
                <Tooltip
                  title={
                    ata.observacoes && ata.observacoes.length > 0
                      ? ata.observacoes.map((obs, index) => (
                          <div key={index}>
                            {index + 1}. {obs.text}
                          </div>
                        ))
                      : "Sem observações"
                  }
                >
                  <p>
                    <strong>{ata.cliente}</strong> - {ata.data}
                  </p>
                </Tooltip>
              </Card>
            </Timeline.Item>
          ))}
        </Timeline>

        {/* Modal para exibir detalhes da ATA */}
        <Modal
          title="Detalhes da ATA"
          open={isModalOpen}
          onCancel={closeModal}
          footer={[
            <Button key="close" onClick={closeModal}>
              Fechar
            </Button>,
          ]}
        >
          {selectedAta && (
            <>
              <p>
                <strong>Cliente:</strong> {selectedAta.cliente}
              </p>
              <p>
                <strong>Data:</strong> {selectedAta.data}
              </p>
              <h4>Observações:</h4>
              <ul>
                {selectedAta.observacoes?.map((obs, index) => (
                  <li key={index}>{obs.text}</li>
                ))}
              </ul>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default TimelineAtas;
