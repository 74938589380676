import React, { createContext, useState, useContext } from 'react';

// Criação do Contexto
const DateRangeContext = createContext();

// Provedor do Contexto
export const DateRangeProvider = ({ children }) => {
  const [dateRange, setDateRange] = useState(null); // Estado global do intervalo de datas

  return (
    <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
      {children}
    </DateRangeContext.Provider>
  );
};

// Hook para facilitar o uso do Contexto
export const useDateRange = () => {
  return useContext(DateRangeContext);
};
