import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { useDateRange } from './DateRangeContext';
import moment from 'moment';

// Função para calcular a cor baseada no valor de cada célula
const getGradientColor = (value, maxValue) => {
  const percentage = value / maxValue;
  return `rgba(74, 20, 140, ${percentage})`; // Usando roxo com opacidade
};

// Função para calcular a cor do texto (preto ou branco) dependendo da luminosidade do fundo
const getTextColor = (value, maxValue) => {
  const percentage = value / maxValue;
  return percentage > 0.5 ? '#FFF' : '#000'; // Texto branco se o fundo for escuro, preto se for claro
};

// Configuração das colunas da tabela
const columns = [
  {
    title: 'Dia',
    dataIndex: 'date_start',
    key: 'date',
    render: (text, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(new Date(text).getTime(), new Date().getTime()),
          color: getTextColor(new Date(text).getTime(), new Date().getTime())
        },
      },
      children: text,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
  {
    title: 'Total Gasto',
    dataIndex: 'spend',
    key: 'spend',
    render: (value, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(value, record.maxSpend),
          color: getTextColor(value, record.maxSpend)
        },
      },
      children: `R$ ${Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
  {
    title: 'Alcance',
    dataIndex: 'reach',
    key: 'reach',
    render: (value, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(value, record.maxReach),
          color: getTextColor(value, record.maxReach)
        },
      },
      children: value,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
  {
    title: 'Impressões',
    dataIndex: 'impressions',
    key: 'impressions',
    render: (value, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(value, record.maxImpressions),
          color: getTextColor(value, record.maxImpressions)
        },
      },
      children: value,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
  {
    title: 'Clique no Link',
    dataIndex: 'clicks',
    key: 'clicks',
    render: (value, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(value, record.maxClicks),
          color: getTextColor(value, record.maxClicks)
        },
      },
      children: value,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
  {
    title: 'CTR Link',
    dataIndex: 'ctr',
    key: 'ctr',
    render: (value, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(value, record.maxCtr),
          color: getTextColor(value, record.maxCtr)
        },
      },
      children: `${value}%`,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
  {
    title: 'Adição ao Carrinho',
    dataIndex: 'add_to_cart',
    key: 'add_to_cart',
    render: (value, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(value, record.maxAddToCart),
          color: getTextColor(value, record.maxAddToCart)
        },
      },
      children: value || 0,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
  {
    title: 'Finalização de Compras',
    dataIndex: 'finalization_of_purchase',
    key: 'checkout_initiated',
    render: (value, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(value, record.maxCheckout),
          color: getTextColor(value, record.maxCheckout)
        },
      },
      children: value || 0,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
  {
    title: 'Compras',
    dataIndex: 'purchase',
    key: 'purchase',
    render: (value, record) => ({
      props: {
        style: {
          borderColor: '#1f1d39',
          background: getGradientColor(value, record.maxPurchase),
          color: getTextColor(value, record.maxPurchase)
        },
      },
      children: value || 0,
    }),
    onHeaderCell: () => ({
      style: { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' },
    }),
  },
];

const DataTable = () => {
  const { dateRange } = useDateRange(); // Obtém o intervalo de datas do contexto
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let startDate, endDate;

      if (dateRange && dateRange.length === 2) {
        startDate = dateRange[0].format('YYYY-MM-DD');
        endDate = dateRange[1].format('YYYY-MM-DD');
      } else {
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        endDate = moment().endOf('month').format('YYYY-MM-DD');
      }

      setLoading(true);
      try {
        const localAdAccountId = localStorage.getItem('token');
        if (!localAdAccountId) {
          console.error('Token não encontrado no localStorage.');
          setLoading(false);
          return;
        }

        const response = await fetch(`https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localAdAccountId}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startDate, endDate }),
        });

        if (!response.ok) {
          console.error('Erro na resposta da API:', response.statusText);
          setLoading(false);
          return;
        }

        const insights = await response.json();

        // Adiciona valores máximos em cada registro para calcular os gradientes
        const maxSpend = Math.max(...insights.map(item => item.spend));
        const maxReach = Math.max(...insights.map(item => item.reach));
        const maxImpressions = Math.max(...insights.map(item => item.impressions));
        const maxClicks = Math.max(...insights.map(item => item.clicks));
        const maxCtr = Math.max(...insights.map(item => item.ctr));
        const maxAddToCart = Math.max(...insights.map(item => item.add_to_cart || 0));
        const maxCheckout = Math.max(...insights.map(item => item.finalization_of_purchase || 0));
        const maxPurchase = Math.max(...insights.map(item => item.purchase || 0));

        // Adiciona os valores máximos a cada item para uso no render
        const transformedData = insights.map(item => ({
          ...item,
          maxSpend,
          maxReach,
          maxImpressions,
          maxClicks,
          maxCtr,
          maxAddToCart,
          maxCheckout,
          maxPurchase,
        }));

        setData(transformedData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dateRange]);

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: '0 auto' }} />;
  }

  return (
    <div style={{ display: 'flex', gap: '20px', padding: '30px', backgroundColor: '#1a1d32', borderRadius: '20px', marginTop: '20px', margin: '30px' }}>
      <div style={{ flex: 1 }}>
        <Table
          style={{ margin: '0px', backgroundColor: 'none', borderRadius: '10px' }}
          columns={columns}
          dataSource={data}
          rowKey="date_start"
        />
      </div>
    </div>
  );
};

export default DataTable;
