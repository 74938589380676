import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Layout, Menu, Spin, Button } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FacebookOutlined,
  CalendarOutlined,
  LineOutlined,
  BulbOutlined,
} from '@ant-design/icons';
import StatsCardData from './StatsCardsData.js';
import WeeklyStats from './WeeklyStats.js';
import WeeklyConversionStats from './WeeklyConversionStats.js';
import DataTable from './DataTable.js';
import AllConts from './AllConts.js';
import Gender from './gender.js';
import Login from './Login.js';
import DashboardGA4 from './DashboardGA4.js';
import DataTableGA4 from './DataTableGA4.js';
import MobileStatsCardsCard from './MobileStatsCardsCard.js';
import { useMediaQuery } from 'react-responsive';
import Funil from './funil.js';
import CarrosselSolicitacoes from './CarrosselSolicitacoes.js';
import TimelineAtas from './TimelineAtas.js';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu; // Importa o componente SubMenu

function ProtectedRoute({ children }) {
  const localAdAccountId = localStorage.getItem('token');
  return localAdAccountId ? children : <Navigate to="/login" />;
}

function App() {
  const [selectedMenu, setSelectedMenu] = useState('Meta');
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isDesktop = useMediaQuery({ minWidth: 769 });

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Spin size="large" />
      </div>
    );
  }

  const renderContent = () => {
    if (selectedMenu === 'Meta') {
      return (
        <div style={{ padding: 10 }}>
          {isMobile && <MobileStatsCardsCard />}
          {isDesktop && (
            <>
              <CarrosselSolicitacoes />
              <AllConts />
              <StatsCardData />
              <WeeklyStats />
              <WeeklyConversionStats />
              <DataTable />
              <Funil />
            </>
          )}
        </div>
      );
    } else if (selectedMenu === 'Calendário estratégico') {
      return (
        <div style={{ padding: 10 }}>
          <iframe
            src="https://calendarioumbrella10.web.app/"
            title="Calendário Estratégico"
            style={{
              width: '100%',
              height: '90vh',
              border: 'none',
              borderRadius: '10px',
              overflow: 'hidden',
              minHeight: '600px',
            }}
          ></iframe>
        </div>
      );
    } else if (selectedMenu === 'Google Analitycs') {
      return (
        <div style={{ padding: 10 }}>
          <DashboardGA4 />
          <DataTableGA4 />
        </div>
      );
    } else if (selectedMenu === 'Timeline') {
      return (
        <div
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <TimelineAtas />
        </div>
      );
    } else if (selectedMenu === 'SubCategoria1') {
      return <div style={{ padding: 10 }}>Conteúdo da Subcategoria 1</div>;
    } else if (selectedMenu === 'SubCategoria2') {
      return <div style={{ padding: 10 }}>Conteúdo da Subcategoria 2</div>;
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ backgroundColor: '#1a1d32' }}
      >
        <div
          style={{
            height: "48px",
            margin: "6px",
            backgroundColor: "white",
            borderRadius: "8px",
            textAlign: "center",
            lineHeight: "48px",
            color: "#3a0c8d",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="https://i.ibb.co/68TWphZ/Umbrella-Traffic1.png"
            alt="Evo"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              height: "100%",
              objectFit: "contain",
              borderRadius: "8px",
            }}
          />
        </div>

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedMenu]}
          onClick={(e) => setSelectedMenu(e.key)}
          style={{ backgroundColor: '#1a1d32', color: 'white' }}
        >
          <Menu.Item
            key="Meta"
            icon={<FacebookOutlined />}
            style={{
              backgroundColor: selectedMenu === 'Meta' ? '#3a0d8d' : 'transparent',
              color: 'white',
              borderRadius: '8px',
            }}
          >
            Facebook
          </Menu.Item>
          <Menu.Item
            key="Calendário estratégico"
            icon={<CalendarOutlined />}
            style={{
              backgroundColor: selectedMenu === 'Calendário estratégico' ? '#3a0d8d' : 'transparent',
              color: 'white',
              borderRadius: '8px',
            }}
          >
            Calendário estratégico
          </Menu.Item>
          <Menu.Item
            key="Timeline"
            icon={<LineOutlined />}
            style={{
              backgroundColor: selectedMenu === 'Timeline' ? '#3a0d8d' : 'transparent',
              color: 'white',
              borderRadius: '8px',
            }}
          >
            Timeline
          </Menu.Item>

          {/* Menu com Subcategorias */}
          <SubMenu
            key="link"
            icon={<BulbOutlined />}
            title="Links úteis"
            style={{
              backgroundColor: selectedMenu === 'link' ? '#1a1d32' : 'transparent',
              borderRadius: '8px',
            }}
          >
            <Menu.Item
              key="SubCategoria1"
              onClick={() => window.open("https://blog-umbrella-5fc0a.web.app/post/1", "_blank")}
              style={{
                backgroundColor: selectedMenu === 'SubCategoria1' ? '#3a0c8d' : 'transparent', // Cor de seleção
                color: selectedMenu === 'SubCategoria1' ? 'white' : '#aaa', // Texto branco ao selecionar
                borderRadius: '8px',
              }}
            >
              Guia de medidas
            </Menu.Item>
            <Menu.Item
              key="SubCategoria2"
              onClick={() => window.open("https://blog-umbrella-5fc0a.web.app/post/2", "_blank")}
              style={{
                backgroundColor: selectedMenu === 'SubCategoria2' ? '#3a0c8d' : 'transparent', // Cor de seleção
                color: selectedMenu === 'SubCategoria2' ? 'white' : '#aaa', // Texto branco ao selecionar
                borderRadius: '8px',
              }}
            >
              Guia de promoções
            </Menu.Item>
          </SubMenu>

        </Menu>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: '#0f0e1f' }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{ fontSize: '16px', width: 64, height: 64, color: 'white' }}
          />
        </Header>
        <Content style={{ padding: '0 10px', backgroundColor: '#0f0e1f', color: 'white' }}>
          <Gender />
          {renderContent()}
        </Content>
        <Footer style={{ textAlign: 'center', background: '#0f0e20', color: 'white' }}>
          Umbrella Traffic technology ©2024 Created by Umbrella Traffic
        </Footer>
      </Layout>
    </Layout>
  );
}

export default function MainApp() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}
