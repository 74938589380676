import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useDateRange } from './DateRangeContext';
import moment from 'moment';

const formatToBRL = (value) => `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

const CustomChart = ({ title, dataKeyBar, dataKeyLine, barColor, lineColor, formatAsCurrency, data }) => (
  <Card
    title={title}
    headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
    bodyStyle={{ backgroundColor: '#1f1d39', color: 'white' }}
    bordered={false}
    style={{ width: '100%' }}
  >
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart data={data}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={formatAsCurrency ? formatToBRL : (value) => value} />
        <Tooltip formatter={(value) => (formatAsCurrency ? formatToBRL(value) : value)} />
        <Legend />
        <Bar dataKey={dataKeyBar} barSize={20} fill={barColor} />
        <Line
          type="monotone"
          dataKey={dataKeyLine}
          stroke={lineColor}
          strokeWidth={2}
          dot={{ r: 6, stroke: lineColor, strokeWidth: 2, fill: lineColor }}
          activeDot={{ r: 8, stroke: lineColor, strokeWidth: 2, fill: lineColor }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  </Card>
);

const WeeklyStats = () => {
  const { dateRange } = useDateRange();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let startDate, endDate;
  
      // Verifica se o intervalo de datas foi selecionado
      if (dateRange && dateRange.length === 2) {
        startDate = dateRange[0].format('YYYY-MM-DD');
        endDate = dateRange[1].format('YYYY-MM-DD');
      } else {
        // Últimos 7 dias até a data atual
        startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
      }
  
      setLoading(true);
      try {
        const localAdAccountId = localStorage.getItem('token');
        if (!localAdAccountId) {
          console.error('Token não encontrado no localStorage.');
          setLoading(false);
          return;
        }
  
        const response = await fetch(`https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localAdAccountId}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startDate, endDate }),
        });
  
        if (!response.ok) {
          console.error('Erro na resposta da API:', response.statusText);
          setLoading(false);
          return;
        }
  
        const jsonResponse = await response.json();
  
        console.log('Dados recebidos da API:', jsonResponse);
  
        const formattedData = jsonResponse.map((item) => ({
          name: item.date_start,
          valor: parseFloat(item.purchase_value) || 0,
          compradores: parseFloat(item.purchase) || 0,
          add_to_cart: parseFloat(item.add_to_cart) || 0,
          roas: parseFloat(item.purchase_roas?.[0]) || 0,
        }));
  
        setData(formattedData);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [dateRange]);
  

  if (loading) {
    return <div style={{ textAlign: 'center', color: 'white' }}>Carregando...</div>;
  }

  const gradientBorderStyle = {
    background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
    padding: '1px',
    borderRadius: '10px',
  };

  return (
    <div style={{ padding: '30px', backgroundColor: '#1a1d32', borderRadius: '20px', margin: '30px' }}>
      <Row gutter={[24, 24]} justify="center">
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <div style={gradientBorderStyle}>
            <CustomChart
              title="Faturamento"
              dataKeyBar="valor"
              dataKeyLine="valor"
              barColor="#413ea0"
              lineColor="#00FF00"
              formatAsCurrency={true}
              data={data}
            />
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <div style={gradientBorderStyle}>
            <CustomChart
              title="Compras"
              dataKeyBar="compradores"
              dataKeyLine="compradores"
              barColor="#413ea0"
              lineColor="#00FFFF"
              formatAsCurrency={false}
              data={data}
            />
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <div style={gradientBorderStyle}>
            <CustomChart
              title="Adições ao Carrinho"
              dataKeyBar="add_to_cart"
              dataKeyLine="add_to_cart"
              barColor="#413ea0"
              lineColor="#FF00FF"
              formatAsCurrency={false}
              data={data}
            />
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <div style={gradientBorderStyle}>
            <CustomChart
              title="ROAS"
              dataKeyBar="roas"
              dataKeyLine="roas"
              barColor="#413ea0"
              lineColor="#FFFF00"
              formatAsCurrency={false}
              data={data}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WeeklyStats;
