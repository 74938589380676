import React from 'react';
import { DatePicker, Button, Dropdown, Menu } from 'antd';
import { useDateRange } from './DateRangeContext'; // Importa o contexto
import moment from 'moment';

const { RangePicker } = DatePicker;

const quickOptions = [
  { label: 'Mês passado', range: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')] },
  { label: 'Último trimestre', range: [moment().subtract(3, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')] },
  { label: 'Último semestre', range: [moment().subtract(6, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')] },
  { label: 'Este ano', range: [moment().startOf('year'), moment()] },
  { label: 'Ano passado', range: [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')] },
];

const DateRangeSelector = () => {
  const { dateRange, setDateRange } = useDateRange(); // Acessa o estado global

  const handleQuickOptionClick = (range) => {
    setDateRange(range); // Atualiza o intervalo global
  };

  const handleDateChange = (dates) => {
    setDateRange(dates); // Atualiza o intervalo global
  };

  const menu = (
    <Menu>
      {quickOptions.map((option, index) => (
        <Menu.Item key={index} onClick={() => handleQuickOptionClick(option.range)}>
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <Dropdown overlay={menu} placement="bottomLeft" arrow>
        <Button>Opções rápidas</Button>
      </Dropdown>
      <RangePicker
        onChange={handleDateChange}
        value={dateRange}
        format="DD/MM/YYYY"
        style={{ width: '300px' }}
      />
      <Button
        style={{ backgroundColor: '#3a0d8d', color: 'white', border: 'none' }}
        onClick={() => console.log('Intervalo de datas:', dateRange)}
      >
        Aplicar
      </Button>
    </div>
  );
};

export default DateRangeSelector;
