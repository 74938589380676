import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { useDateRange } from './DateRangeContext';
import moment from 'moment';

const formatToBRL = (value) => `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

const RADIAN = Math.PI / 180;

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => { total += v.value; });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx;
  const y0 = cy;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key="needle-circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path key="needle-path" d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} fill={color} />
  ];
};

const PieChartWithNeedle = ({ title, value }) => {
  const cx = 150;
  const cy = 120;
  const iR = 50;
  const oR = 80;
  const needleColor = "#d0d000";
  const data = [
    { name: 'Ruim', value: 1, color: '#FF0000' },
    { name: 'Médio', value: 1, color: '#FFA500' },
    { name: 'Bom', value: 8, color: '#00FF00' },
  ];

  const gradientBorderStyle = {
    background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
    padding: '1px',
    borderRadius: '10px',
    width: '100%',
  };

  return (
    <div style={gradientBorderStyle}>
      <Card
        title={title}
        headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
        bodyStyle={{ backgroundColor: '#1f1d39', color: 'white', textAlign: 'center' }}
        bordered={false}
        style={{ width: '100%', backgroundColor: '#1f1d39', borderRadius: '10px' }}
      >
        <PieChart width={250} height={200}>
          <Pie dataKey="value" startAngle={180} endAngle={0} data={data} cx={cx} cy={cy} innerRadius={iR} outerRadius={oR} stroke="none">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(value, data, cx, cy, iR, oR, needleColor)}
          <text x={cx} y={cy + 20} textAnchor="middle" dominantBaseline="central" fill="#FFFFFF" fontSize={24} fontWeight="bold">
            {`${value.toFixed(2)}%`}
          </text>
        </PieChart>
      </Card>
    </div>
  );
};

const CustomChart = ({ title, dataKeyBar, dataKeyLine, barColor, lineColor, formatAsCurrency, data }) => {
  const gradientBorderStyle = {
    background: 'linear-gradient(135deg, #00FFDB, #3A0D8E)',
    padding: '1px',
    borderRadius: '10px',
    width: '100%',
  };

  return (
    <div style={gradientBorderStyle}>
      <Card
        title={title}
        headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
        bodyStyle={{ backgroundColor: '#1f1d39', color: 'white' }}
        bordered={false}
        style={{ width: '100%', backgroundColor: '#1f1d39', borderRadius: '10px' }}
      >
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart data={data}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(value) => (formatAsCurrency ? formatToBRL(value) : value.toFixed(2))} />
            <Tooltip formatter={(value) => (formatAsCurrency ? formatToBRL(value) : value.toFixed(2))} />
            <Legend />
            <Bar dataKey={dataKeyBar} barSize={20} fill={barColor} />
            <Line type="monotone" dataKey={dataKeyLine} stroke={lineColor} strokeWidth={2}
            dot={{ r: 6, stroke: lineColor, strokeWidth: 2, fill: lineColor }}
            activeDot={{ r: 8, stroke: lineColor, strokeWidth: 2, fill: lineColor }}
             />
          </ComposedChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

const WeeklyConversionStats = () => {
  const { dateRange } = useDateRange(); // Obtém o intervalo de datas global
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [taxaConversaoMediaTotal, setTaxaConversaoMediaTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      // Define o intervalo de datas
      const startDate = dateRange && dateRange.length === 2
        ? dateRange[0].format('YYYY-MM-DD') // Intervalo selecionado pelo usuário
        : moment().subtract(7, 'days').format('YYYY-MM-DD'); // Últimos 7 dias (caso nenhuma data seja selecionada)
  
      const endDate = dateRange && dateRange.length === 2
        ? dateRange[1].format('YYYY-MM-DD') // Intervalo selecionado pelo usuário
        : moment().format('YYYY-MM-DD'); // Até a data atual
  
      setLoading(true);
      try {
        const localAdAccountId = localStorage.getItem('token');
        if (!localAdAccountId) {
          console.error('Token não encontrado no localStorage.');
          setLoading(false);
          return;
        }
  
        const response = await fetch(`https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localAdAccountId}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startDate, endDate }),
        });
  
        if (!response.ok) {
          console.error('Erro na resposta da API:', response.statusText);
          setLoading(false);
          return;
        }
  
        const jsonResponse = await response.json();
        console.log('Dados recebidos da API:', jsonResponse);
  
        const apiData = jsonResponse.map((item) => ({
          name: item.date_start,
          ticketMedio: parseFloat(item.purchase_value) / parseInt(item.purchase, 10) || 0,
          taxaConversao: ((parseInt(item.purchase, 10) / parseInt(item.landing_page_view, 10)) * 100) || 0,
          custoCPC: parseFloat(item.cpc) || 0,
        }));
  
        const totalConversions = apiData.reduce((acc, item) => acc + item.taxaConversao, 0);
        setTaxaConversaoMediaTotal(totalConversions / apiData.length);
  
        setData(apiData);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [dateRange]);
  

  if (loading) return <div style={{ textAlign: 'center', color: 'white' }}>Carregando...</div>;

  return (
    <div style={{ padding: '30px', backgroundColor: '#1a1d32', borderRadius: '20px', margin: '30px' }}>
      <Row gutter={[24, 24]} justify="center">
        <Col xs={24} sm={12} lg={6}>
          <CustomChart title="Ticket Médio" dataKeyBar="ticketMedio" dataKeyLine="ticketMedio" barColor="#413ea0" lineColor="#00FF00" formatAsCurrency data={data} />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <CustomChart title="Taxa de Conversão" dataKeyBar="taxaConversao" dataKeyLine="taxaConversao" barColor="#413ea0" lineColor="#00FFFF" data={data} />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <CustomChart title="Custo do CPC" dataKeyBar="custoCPC" dataKeyLine="custoCPC" barColor="#413ea0" lineColor="#FF00FF" formatAsCurrency data={data} />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <PieChartWithNeedle title="Taxa de Conversão Média" value={taxaConversaoMediaTotal} />
        </Col>
      </Row>
    </div>
  );
};

export default WeeklyConversionStats;
