import React, { useEffect, useState } from 'react';
import { FunnelChart, Funnel, LabelList, Tooltip } from 'recharts';
import { useDateRange } from './DateRangeContext'; // Importa o contexto do intervalo de datas

const Funil = () => {
  const { dateRange } = useDateRange(); // Obtém o intervalo de datas do contexto
  const [insights, setInsights] = useState([]); // Estado para armazenar os dados
  const [loading, setLoading] = useState(true); // Estado para controle de carregamento
  const [error, setError] = useState(null); // Estado para erros

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        setLoading(true);
        const localAdAccountId = localStorage.getItem('token');

        if (!localAdAccountId) {
          throw new Error('ID da conta de anúncios não encontrado no localStorage');
        }

        // Monta o corpo da requisição com base no intervalo de datas
        const body = dateRange && dateRange.length === 2
          ? { startDate: dateRange[0].format('YYYY-MM-DD'), endDate: dateRange[1].format('YYYY-MM-DD') }
          : { filter: 'ultimos_30' }; // Padrão para os últimos 30 dias

        const response = await fetch(
          `https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`,
          {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localAdAccountId}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }
        );

        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }

        const data = await response.json();
        setInsights(data); // Armazena os dados diretamente
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setError(error.message); // Armazena o erro para exibição
      } finally {
        setLoading(false);
      }
    };

    fetchInsights();
  }, [dateRange]); // Atualiza os dados sempre que o intervalo de datas mudar

  if (loading) {
    return <div style={{ textAlign: 'center', color: 'white' }}>Carregando dados do funil...</div>;
  }

  if (error) {
    return <div style={{ textAlign: 'center', color: 'white' }}>Erro: {error}</div>;
  }

  if (insights.length === 0) {
    return <div style={{ textAlign: 'center', color: 'white' }}>Nenhum dado disponível.</div>;
  }

  // Pegando os valores totais das etapas
  const totalViews = insights.reduce((acc, item) => acc + parseInt(item.landing_page_view || 0), 0);
  const totalAddToCart = insights.reduce((acc, item) => acc + parseInt(item.add_to_cart || 0), 0);
  const totalFinalizations = insights.reduce((acc, item) => acc + parseInt(item.finalization_of_purchase || 0), 0);
  const totalPurchases = insights.reduce((acc, item) => acc + parseInt(item.purchase || 0), 0);

  // Preparando os dados para o gráfico
  const data = [
    { name: 'Visualizações da Página', value: totalViews, fill: '#6A5ACD' }, // Roxo claro
    { name: 'Adições ao Carrinho', value: totalAddToCart, fill: '#7B68EE' }, // Roxo intermediário
    { name: 'Finalizações de Compra', value: totalFinalizations, fill: '#9370DB' }, // Lavanda
    { name: 'Compras Concluídas', value: totalPurchases, fill: '#BA55D3' }, // Roxo escuro
  ];

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#1a1d32', // Cor de fundo do gráfico
        padding: '20px',
        margin: '30px',
        borderRadius: '20px',
      }}
    >
      <h1 style={{ color: '#FFFFFF', marginBottom: '20px', textAlign: 'center' }}>Funil de Conversão</h1>
      <FunnelChart width={600} height={400}>
      <Tooltip
  contentStyle={{
    backgroundColor: '#1a1d32', // Cor de fundo do tooltip
    color: '#FFFFFF', // Cor do texto principal
    borderRadius: '10px',
  }}
  cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
  itemStyle={{
    color: '#FFFFFF', // Cor do texto dos itens
    fontSize: '14px', // Tamanho da fonte
  }}
/>

        <Funnel dataKey="value" data={data} isAnimationActive>
          <LabelList
            position="right"
            dataKey="name"
            fill="#FFFFFF"
            fontSize="14px"
            style={{ textAlign: 'center' }}
          />
        </Funnel>
      </FunnelChart>
    </div>
  );
};

export default Funil;
