import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { Carousel, Card, Spin } from "antd";

// Importação da configuração do Firebase específica (já inicializada em outro arquivo)
import { db } from "./firebase"; // Arquivo com as inicializações do Firebase

const { Meta } = Card;

const CarrosselSolicitacoes = () => {
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [loading, setLoading] = useState(true);

  // Busca as solicitações no Firestore
  const fetchSolicitacoes = async () => {
    setLoading(true);
    try {
      const tokenBM = localStorage.getItem("tokenBM");
      if (!tokenBM) return;

      // Consulta no Firestore usando a coleção "requests"
      const q = query(collection(db, "requests"), where("arquivado", "==", false));
      const querySnapshot = await getDocs(q);

      // Filtra os dados conforme o "codigoBm" no localStorage
      const filteredData = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((item) => item.codigoBm === tokenBM);

      setSolicitacoes(filteredData);
    } catch (error) {
      console.error("Erro ao buscar solicitações:", error);
    } finally {
      setLoading(false);
    }
  };

  // Carrega os dados assim que o componente é montado
  useEffect(() => {
    fetchSolicitacoes();
  }, []);

  // Caso não tenha solicitações, o componente não renderiza nada
  if (!loading && solicitacoes.length === 0) {
    return null; // Torna o componente invisível
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100px", // Define a altura desejada
        overflow: "hidden", // Evita que o carrossel extrapole
        backgroundColor: "#1a1d32",
        borderRadius: "10px"
      }}
    >
      {loading ? (
        <Spin size="large" />
      ) : (
        <Carousel autoplay autoplaySpeed={3000} infinite>
          {solicitacoes.map((item) => (
            <div key={item.id} style={{ height: "50px" }}>
              <Card
                hoverable
                style={{
                  width: "100%",
                  height: "85px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: "#1a1d32",
                  border: "none",
                }}
              >
                <Meta
                  title={
                    <span style={{ fontSize: "20px", fontWeight: "bold", color: "white" }}>
                      Solicitação: {item.solicitacao}
                    </span>
                  }
                  description={
                    <span style={{ fontSize: "12px", color: "white" }}>
                      Data da solicitação: {item.data}
                    </span>
                  }
                />
              </Card>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default CarrosselSolicitacoes;
