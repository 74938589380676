import React, { useEffect, useState } from 'react';
import { Card, Statistic } from 'antd';
import CountUp from 'react-countup';
import Dashboard from './dashboard';
import MetaDashboard from './MetaDashboard';
import DateRangeSelector from './DateRangeSelector'; // Componente de seleção de intervalo de datas

// Função de formatação para a contagem animada
const formatter = (value) => <CountUp end={value} separator="," duration={5} />;

const MobileStatsCardsCard = () => {
  const [dateRange, setDateRange] = useState(null); // Intervalo de datas selecionado
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [aggregatedStats, setAggregatedStats] = useState({
    revenue: 0,
    invested: 0,
    purchases: 0,
    averageTicket: 0,
    roas: 0,
    dailyInvestedAvg: 0,
    dailyRevenueAvg: 0,
    todayInvested: 0,
    todayRevenue: 0,
    yesterdayInvested: 0,
    yesterdayRevenue: 0,
  });

  useEffect(() => {
    const fetchInsights = async () => {
      setLoading(true);

      try {
        const localAdAccountId = localStorage.getItem('token');
        const body = dateRange
          ? { startDate: dateRange[0].format('YYYY-MM-DD'), endDate: dateRange[1].format('YYYY-MM-DD') }
          : { filter: 'ultimos_30' };

        const response = await fetch(
          `https://ads-meta-867395045570.us-central1.run.app/api/insights/${localAdAccountId}`,
          {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localAdAccountId}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }
        );

        const data = await response.json();
        setInsights(data); // Armazena os dados recebidos
        aggregateStats(data); // Atualiza os dados agregados para os cards
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInsights(); // Busca os dados sempre que o intervalo de datas mudar
  }, [dateRange]);

  const aggregateStats = (data) => {
    const totalDays = data.length;

    const totalStats = data.reduce(
      (acc, day) => {
        acc.invested += parseFloat(day.spend) || 0;
        acc.revenue += parseFloat(day.purchase_value) || 0;
        acc.purchases += parseInt(day.purchase, 10) || 0;
        acc.roas += parseFloat(day.purchase_roas[0]) || 0;
        return acc;
      },
      {
        invested: 0,
        revenue: 0,
        purchases: 0,
        roas: 0,
      }
    );

    totalStats.roas = totalDays ? totalStats.roas / totalDays : 0;
    totalStats.averageTicket = totalStats.purchases ? totalStats.revenue / totalStats.purchases : 0;
    totalStats.dailyInvestedAvg = totalDays ? totalStats.invested / totalDays : 0;
    totalStats.dailyRevenueAvg = totalDays ? totalStats.revenue / totalDays : 0;

    const todayData = data[data.length - 1] || {};
    const yesterdayData = data[data.length - 2] || {};

    totalStats.todayInvested = parseFloat(todayData.spend) || 0;
    totalStats.todayRevenue = parseFloat(todayData.purchase_value) || 0;
    totalStats.yesterdayInvested = parseFloat(yesterdayData.spend) || 0;
    totalStats.yesterdayRevenue = parseFloat(yesterdayData.purchase_value) || 0;

    setAggregatedStats(totalStats);
  };

  const handleDateRangeApply = (range) => {
    setDateRange(range); // Atualiza o intervalo de datas
  };

  const {
    revenue,
    invested,
    purchases,
    averageTicket,
    roas,
    dailyInvestedAvg,
    dailyRevenueAvg,
    todayInvested,
    todayRevenue,
    yesterdayInvested,
    yesterdayRevenue,
  } = aggregatedStats;

  const cardStyle = {
    marginBottom: '10px',
    borderRadius: '10px',
    overflow: 'hidden',
  };

  const cardHeadStyle = { backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' };
  const cardBodyStyle = { backgroundColor: '#1f1d39', color: 'white', textAlign: 'center', padding: '20px' };

  if (loading) {
    return <p style={{ color: 'white' }}>Carregando dados...</p>;
  }

  return (
    <div style={{ padding: '15px', backgroundColor: '#1a1d32', borderRadius: '10px', color: 'white' }}>
      {dateRange && (
        <div style={{ textAlign: 'center', marginBottom: '15px', color: 'white' }}>
          <h3>
            Intervalo: {dateRange[0]?.format('DD/MM/YYYY')} - {dateRange[1]?.format('DD/MM/YYYY')}
          </h3>
        </div>
      )}

      <div style={{ marginBottom: '15px', color: 'white' }}>
        <DateRangeSelector onApply={handleDateRangeApply} />
      </div>

      <div>
        <CardItem title="Receita Captada" value={revenue} prefix="R$" style={cardStyle} />
        <CardItem title="Valor Investido" value={invested} prefix="R$" style={cardStyle} />
        <CardItem title="Qnt. De Compras" value={purchases} style={cardStyle} />
        <CardItem title="Ticket Médio" value={averageTicket} prefix="R$" style={cardStyle} />
        <CardItem title="ROAS" value={roas} style={cardStyle} />
        <CardItem title="Investimento Médio Diário" value={dailyInvestedAvg} prefix="R$" style={cardStyle} />
        <CardItem title="Faturamento Médio Diário" value={dailyRevenueAvg} prefix="R$" style={cardStyle} />
        <CardItem title="Investimento Hoje" value={todayInvested} prefix="R$" style={cardStyle} />
        <CardItem title="Faturamento Hoje" value={todayRevenue} prefix="R$" style={cardStyle} />
        <CardItem title="Investimento Ontem" value={yesterdayInvested} prefix="R$" style={cardStyle} />
        <CardItem title="Faturamento Ontem" value={yesterdayRevenue} prefix="R$" style={cardStyle} />
      </div>

      <div style={{ marginTop: '15px', color: 'white' }}>
        <Card title="Desempenho Diário" headStyle={cardHeadStyle} bodyStyle={cardBodyStyle}>
          <Dashboard dateRange={dateRange} />
        </Card>
        <div style={{ marginTop: '10px' }}>
          <MetaDashboard />
        </div>
      </div>
    </div>
  );
};

// Componente auxiliar para renderizar os cards de estatísticas
const CardItem = ({ title, value, prefix, style }) => {
  return (
    <Card
      title={<span style={{ color: 'white' }}>{title}</span>}
      headStyle={{ backgroundColor: '#3A0D8E', color: 'white', textAlign: 'center' }}
      bodyStyle={{ backgroundColor: '#1f1d39', color: 'white', textAlign: 'center', padding: '20px' }}
      bordered={false}
      style={style}
    >
      <Statistic value={value} precision={2} prefix={prefix} formatter={formatter} valueStyle={{ color: 'white' }} />
    </Card>
  );
};

export default MobileStatsCardsCard;
