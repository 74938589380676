import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import "./ButtonProj.css";
import SimpleDashboard from "./SimpleDashboard"; // Importando o SimpleDashboard

const ButtonProj = ({ icon = "+", style = {} }) => {
  const [isModalVisible, setIsModalVisible] = useState(false); // Controle da visibilidade do modal
  const [loading, setLoading] = useState(false); // Simula carregamento (caso necessário)

  // Controla a abertura e fechamento do modal
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  return (
    <div>
      {/* Botão estilizado */}
      <div className="pulsar" onClick={showModal} style={style}>
        <span>{icon}</span>
      </div>

      {/* Modal */}
      <Modal
        title="Dashboard de Projeções"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1200} // Largura ajustada para comportar o conteúdo do SimpleDashboard
        bodyStyle={{
          backgroundColor: "#1a1d32", // Fundo do corpo do modal
          padding: "20px", // Adiciona padding interno
          color: "#ffffff", // Cor do texto para contraste
        }}
        style={{
          borderRadius: "8px", // Borda arredondada do modal
        }}
      >
        {loading ? (
          <Spin tip="Carregando dados..." />
        ) : (
          // Renderiza o conteúdo do SimpleDashboard dentro do modal
          <SimpleDashboard />
        )}
      </Modal>
    </div>
  );
};

export default ButtonProj;
